<template>
    <v-card flat tile :dark="data.value.dark_theme" :style="getHeaderStyle(data.value)">
      <v-container>
        <v-row v-if="showTitle">
          <v-col>
            <h1 class="text-h1 heading-shadow">{{ headerTitle  }}</h1>
          </v-col>
        </v-row>
        <StreamfieldBlock v-for="(block, j) in data.value.rows" :key="j" :block="block" />
        <div v-if="data.value.navigation" role="navigation" :aria-label="this.ariaLabel">
          <StreamfieldBlock v-for="(block, j) in data.value.navigation" :key="j" :block="block" />
        </div>
      </v-container>
    </v-card>
</template>

<script>
import StreamfieldBlock from './streamfieldBlock.vue'

export default {
  name: "fullWidthHeaderBlock",
  props: ["data", "headerTitle"],
  data: () => ({
    ariaLabel: "secondary",
     showTitle: { type: Boolean, default: true },
    showHeader: {type: Boolean, default: true }
  }),
  mounted() { 
        console.log(this.siteStructure, "data from header")
        this.$store.dispatch("wagtail/getSiteStructure");
    console.log(this.data, this.headerTitle , "container data")

    if(this.siteStructure.length != 0){
      this.ariaLabel = "tertiary"
    }else {
      this.ariaLabel = "secondary"
    }
  },
  components: { StreamfieldBlock },
  methods: {
    getHeaderStyle(container) {
        console.log(container, "this cont")
      let style = ""
      if (container.background_image !== null)
        style += ' background-size: cover; background-image: url('+container.background_image.file+');'
      if (container.background_colour !== null)
        style += ' background-color: '+container.background_colour+';'
      return style
    }
  },
  computed: {
       siteStructure() {
      let siteStructure = [];
      for (const item of this.$store.state.wagtail.siteStructure) {
        //TODO: Unsure how reliable this is.  Looks at the URL to calculate depth by counting slashes in the URL
        //Keep Icon for L0 no text, keep text no icon for L1
        if (item.depth == 0 || item.depth == 1){
          item.depth = item.meta.html_url.split("/").length - 4;
          let url = new URL(item.meta.html_url);
          item.pathname = url.pathname;
          item.menutitle = Array(item.depth + 1).join(" -") + " " + item.title;
          console.log(item,item.depth, "item depth")
          siteStructure.push(item);
        }
      }

      return siteStructure;
    },
  }
};
</script>

<style scoped>
.heading-shadow {
  text-shadow: 0px 0px 0.4em rgb(2 18 26 / 100%);
  }
</style>